const MatchStandingPageReducer = (state, action) => {
  switch (action.type) {
    case 'MATCH_STANDING_FETCHING':
      return {...state, isFetching: true};
    case 'MATCH_STANDING_UPDATE':
      return {...state, content: action.data, isFetching: false};
    case 'MATCH_STANDING_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: false,
      };
    default:
      return state;
  }
};

const MatchMetaPageReducer = (state, action) => {
  switch (action.type) {
    //  MATCH_PAGE HEADER
    case 'MATCH_UPDATE':
      return {...state, matchInfo: action.data, isFetching: false};
    case 'MATCH_FETCHING':
      return {...state, isFetching: true};
    case 'MATCH_WS':
      const mergedFlags = [...(state.matchInfo.flags ?? []), ...(action.data.flags ?? [])];
      const flatFlags = [...new Set(mergedFlags)];
      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          live: {
            ...state.matchInfo.live,
            minute: action.data.minute,
            additionalMinute: action.data.additionalMinute,
          },
          flags: flatFlags,
          lastSofttickerReporter: action.data.lastSofttickerReporter ?? undefined,
          softtickerEventsReportableUntil: action.data.softtickerEventsReportableUntil ?? null,
          // If we use (||), the value of 0 or an empty string will be considered as undefined or NULL and return the default value (state.matchInfo.awayGoal)
          // Use nullish coalescing operator (??) to prevent overriding with null
          awayGoal: action.data.awayGoal ?? state.matchInfo.awayGoal,
          homeGoal: action.data.homeGoal ?? state.matchInfo.homeGoal,
        },
      };
    case 'MATCH_HIGHLIGHT_WS':
      let highlights = [...state.matchInfo.highlights];
      if (action.isUpdate) {
        const eventId = action.data.id;
        const indexToUpdate = highlights.findIndex(highlight => highlight.id === eventId);
        highlights[indexToUpdate] = action.data;
      } else {
        highlights = [...state.matchInfo.highlights, action.data];
      }
      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          highlights,
        },
      };
    case 'MATCH_HIGHLIGHT_DELETE_WS':
      const copyHighlights = [...state.matchInfo.highlights];
      const eventId = action.data.id;
      const indexToDelete = copyHighlights.findIndex(highlight => highlight.id === eventId);

      if (indexToDelete > -1) {
        copyHighlights.splice(indexToDelete, 1);
      }

      return {
        ...state,
        matchInfo: {
          ...state.matchInfo,
          highlights: copyHighlights,
        },
      };

    default:
      return state;
  }
};

const MatchInfoPageReducer = (state, action) => {
  switch (action.type) {
    //  MATCH_PAGE HEADER
    case 'MATCH_INFO_UPDATE':
      return {...state, data: action.data, isFetching: false};
    case 'MATCH_INFO_FETCHING':
      return {...state, isFetching: true};
    case 'MATCH_INFO_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: false,
      };
    default:
      return state;
  }
};

const MatchLineUpPageReducer = (state, action) => {
  switch (action.type) {
    case 'MATCH_LINEUP_FETCHING':
      return {...state, isFetching: true};
    case 'MATCH_LINEUP_UPDATE':
      return {...state, lineup: action.data, isFetching: false};
    case 'MATCH_LINEUP_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: false,
      };
    case 'MATCH_LINEUP_SELECTED_TEAM_UPDATE':
      return {...state, selectedTeam: action.data};
    default:
      return state;
  }
};

const addAdSlotsToMatchEvents = ({items: matchEvents, isDesktop}) => {
  if (!matchEvents || matchEvents.length <= 1) {
    return matchEvents;
  }
  const tickerEvents = [...matchEvents];
  const indexCont1 = 1;
  const adOffset = isDesktop ? 9 : 5;
  const adStart = isDesktop ? 12 : 8;

  tickerEvents.length >= indexCont1 ? tickerEvents.splice(indexCont1, 0, {type: 'adslot', key: 'Content_1'}) : null;
  tickerEvents.length >= adStart ? tickerEvents.splice(adStart, 0, {type: 'adslot', key: 'MatchEvent_1'}) : null;
  tickerEvents.length >= adStart + adOffset
    ? tickerEvents.splice(adStart + adOffset, 0, {type: 'adslot', key: 'Rectangle_1'})
    : null;
  tickerEvents.length >= adStart + adOffset * 2
    ? tickerEvents.splice(adStart + adOffset * 2, 0, {type: 'adslot', key: 'Content_2'})
    : null;
  tickerEvents.length >= adStart + adOffset * 3
    ? tickerEvents.splice(adStart + adOffset * 3, 0, {type: 'adslot', key: 'Rectangle_2'})
    : null;
  return tickerEvents;
};

const MatchTickerPageReducer = (state, action) => {
  switch (action.type) {
    case 'MATCH_TICKER_FETCHING':
      return {...state, isFetching: true};
    case 'MATCH_TICKER_OVERWRITE':
      return action.data;
    case 'MATCH_TICKER_UPDATE':
      const newItems = addAdSlotsToMatchEvents(action.data);
      return {...state, items: newItems, isFetching: false};
    case 'MATCH_TICKER_WS':
      const items = {...state};
      const prevItems = items.items ?? [];
      if (action.isUpdate) {
        const eventId = action.data.entity.id;
        const indexToUpdate = prevItems.findIndex(event => event.type !== 'adslot' && event.entity.id === eventId);
        if (indexToUpdate > -1) {
          items.items[indexToUpdate] = action.data;
        }
      } else {
        items.items = action.data.items.concat(prevItems);
      }
      return items;
    case 'MATCH_TICKER_WS_DELETE':
      const copyState = {...state};
      const currentItems = copyState.items ?? [];
      const eventId = action.data.entity.id;
      const indexToUpdate = currentItems.findIndex(event => event.type !== 'adslot' && event.entity.id === eventId);
      if (indexToUpdate > -1) {
        copyState.items.splice(indexToUpdate, 1); // 2nd parameter means remove one item only
      }
      return copyState;
    case 'MATCH_TICKER_ERROR':
      return {
        ...state,
        error: action.data,
        nextUrl: null,
        isFetching: false,
      };
    case 'MATCH_VIDEOS_FETCHING':
      return {...state, isFetchingVideos: true};
    case 'MATCH_VIDEOS_UPDATE':
      return {...state, videos: action.data, isFetchingVideos: false};
    case 'WEBSOCKET_ADD_VIDEO':
      const MatchVideosState = {...state};
      const oldVideos = MatchVideosState.videos ?? [];
      MatchVideosState.videos = oldVideos.concat(action.data);
      return MatchVideosState;
    case 'WEBSOCKET_MATCH_VIDEOS_UPDATE':
      const MatchVideos = state.videos ? [...state.videos] : [];
      const videoToUpdateIndex = MatchVideos.findIndex(video => video.data.id === action.data.matchPlaylistId);
      if (videoToUpdateIndex < 0) {
        return state;
      }
      const videoToUpdate = {...MatchVideos[videoToUpdateIndex]};
      videoToUpdate.data.videosCount += 1;
      videoToUpdate.updatedAt = Math.floor(new Date().getTime() / 1000);
      MatchVideos[videoToUpdateIndex] = videoToUpdate;
      return {...state, videos: MatchVideos};
    case 'MATCH_VIDEOS_ERROR':
      return {
        ...state,
        errorVideos: action.data,
        isFetchingVideos: false,
      };
    default:
      return state;
  }
};

export {
  MatchMetaPageReducer,
  MatchStandingPageReducer,
  MatchLineUpPageReducer,
  MatchTickerPageReducer,
  MatchInfoPageReducer,
};
