import {api, authorizedAPI} from 'app/services/api';
import {catchDataError, checkApiRedirect, RedirectException} from 'app/helpers/apiHelpers';
import {generateMatchCompetitionInfo} from 'app/helpers/actionsHelpers';
import {convertToRoman} from 'app/helpers/convertToRoman';
import {changeHeaderText} from 'app/actions/headerActions';
import {trySettingDefaultDistrict} from 'app/actions/sessionActions';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpError} from 'app/components/error/ErrorHandlerActions';

const getMatch = (matchSlug, ts) => {
  const slug = matchSlug.split('@')[0];
  let request = `${process.env.API_URL}v2/matches/${slug}`;
  request += ts ? `?ts=${ts}` : '';
  return api.get(request);
};

const getMatchInfo = matchSlug => {
  const slug = matchSlug.split('@')[0];
  return api.get(`/matches/${slug}/info`);
};

const getMatchStream = (slug, updatedAt) => {
  const matchSlug = slug.split('@')[0];
  let request = `${process.env.API_URL}v2/matches/${matchSlug}/stream`;
  request += updatedAt ? `?ts=${updatedAt}` : '';
  return api.get(request);
};

const getLineup = match => {
  return api.get(`/matches/${match}/lineup`);
};

const getStandingByMatch = matchSlug => {
  return api.get(`/matches/${matchSlug}/standing`);
};

const postTickerLike = matchSlug => {
  return authorizedAPI.post(`${process.env.API_URL}v2/matches/${matchSlug}/ticker/likes`);
};

const deleteTickerLike = matchSlug => {
  return authorizedAPI.delete(`${process.env.API_URL}v2/matches/${matchSlug}/ticker/likes`);
};

const getMatchVideos = (matchSlug, updatedAt) => {
  const request = updatedAt ? `/matches/${matchSlug}/videos?ts=${updatedAt}` : `/matches/${matchSlug}/videos`;
  return api.get(request);
};

function updateMatch(data) {
  return {
    type: 'MATCH_UPDATE',
    data,
  };
}

function wsUpdateMatch(data) {
  return {
    type: 'MATCH_WS',
    data,
  };
}

function wsUpdateHighlights(data, isUpdate) {
  return {
    type: 'MATCH_HIGHLIGHT_WS',
    data: {...data, primaryRole: {...data.primaryRole?.player}},
    isUpdate,
  };
}

function wsDeleteHighlights(data) {
  return {
    type: 'MATCH_HIGHLIGHT_DELETE_WS',
    data,
  };
}

function fetchingMatch() {
  return {
    type: 'MATCH_FETCHING',
  };
}

function setMatchHeader(dispatchRedux, matchInfo) {
  let parent = {
    name: 'ohne Wettbewerb',
    entity: '',
  };

  if (matchInfo.competition) {
    const competitionInfo = generateMatchCompetitionInfo(matchInfo, true);
    if (matchInfo.competition.category.id === 1) {
      parent = {
        name: competitionInfo,
        entity: 'league',
        slugs: {
          leagueSlug: matchInfo.competition.slug,
        },
      };
    } else {
      parent = {
        name: competitionInfo,
        entity: 'cup',
        slugs: {
          cupSlug: matchInfo.competition.slug,
        },
      };
    }
  }

  let homeTeam = matchInfo.homeTeamName;
  let awayTeam = matchInfo.awayTeamName;

  if (matchInfo.homeTeam && matchInfo.homeTeam.clubSlug !== 'dummy') {
    const homeTeamLevel = matchInfo.homeTeam.level > 1 ? `${convertToRoman(matchInfo.homeTeam.level)}` : null;
    homeTeam = homeTeamLevel ? `${matchInfo.homeTeam.name.middle} ${homeTeamLevel}` : matchInfo.homeTeam.name.middle;
  }
  if (matchInfo.awayTeam && matchInfo.awayTeam.clubSlug !== 'dummy') {
    const awayTeamLevel = matchInfo.awayTeam.level > 1 ? `${convertToRoman(matchInfo.awayTeam.level)}` : null;
    awayTeam = awayTeamLevel ? `${matchInfo.awayTeam.name.middle} ${awayTeamLevel}` : matchInfo.awayTeam.name.middle;
  }

  dispatchRedux(
    changeHeaderText({
      title: `${homeTeam} - ${awayTeam}`,
      parent,
    })
  );
}

function fetchMatchMetaDataSSR(slug) {
  return async function (dispatch, getState) {
    try {
      const response = await getMatch(slug);
      const data = response.data;
      checkApiRedirect(data.slug, slug, null, null, getState);
      dispatch(
        updateHistory('undefined', {
          store: 'MatchPage',
          data: {matchInfo: data, isFetching: false},
        })
      );
      setMatchHeader(dispatch, data);
      return trySettingDefaultDistrict(data.competition, dispatch, getState);
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        dispatch(updateHttpError(errorData));
      }
    }
  };
}

function fetchMatchMetaData(slug, forceRefresh, dispatchRedux, ts, getState) {
  return function (dispatch, state) {
    if (!forceRefresh && state.matchInfo?.slug) {
      setMatchHeader(dispatchRedux, state.matchInfo);
      return;
    }
    if (state.matchInfo?.message && state.matchInfo?.status) {
      return;
    }
    dispatch(fetchingMatch());
    return getMatch(slug, ts)
      .then(response => {
        const data = response.data;
        checkApiRedirect(data.slug, slug, null, null);
        dispatch(updateMatch(data));
        setMatchHeader(dispatchRedux, data);
        return trySettingDefaultDistrict(data.competition, dispatchRedux, getState);
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatchRedux(updateHttpError(errorData));
        }
      });
  };
}

//MATCH_TICKER_PAGE
function updateTicker(data) {
  return {
    type: 'MATCH_TICKER_UPDATE',
    data,
  };
}

function wsUpdateTicker(data, isUpdate) {
  return {
    type: 'MATCH_TICKER_WS',
    data,
    isUpdate,
  };
}

function wsDeleteTicker(data) {
  return {
    type: 'MATCH_TICKER_WS_DELETE',
    data,
  };
}

function errorTicker(data) {
  return {
    type: 'MATCH_TICKER_ERROR',
    data,
  };
}

function fetchingTicker() {
  return {
    type: 'MATCH_TICKER_FETCHING',
  };
}

function overwriteTicker(data) {
  return {
    type: 'MATCH_TICKER_OVERWRITE',
    data,
  };
}

function fetchMatchTicker(slug, updatedAt, reloading, forceRefresh, isDesktop) {
  return function (dispatch, state) {
    if (!forceRefresh && !reloading && state.items?.length) {
      return;
    }
    const {isFetching} = state;
    if (!forceRefresh && isFetching) {
      return;
    }
    dispatch(fetchingTicker());
    return getMatchStream(slug, updatedAt)
      .then(response => {
        dispatch(updateTicker({items: response.data, isDesktop}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(errorTicker(errorData));
      });
  };
}

function updateMatchVideos(data) {
  return {
    type: 'MATCH_VIDEOS_UPDATE',
    data,
  };
}

function websocketUpdateMatchVideos(data) {
  return {
    type: 'WEBSOCKET_MATCH_VIDEOS_UPDATE',
    data,
  };
}

function websocketAddMatchVideos(data) {
  return {
    type: 'WEBSOCKET_ADD_VIDEO',
    data,
  };
}

function errorMatchVideos(data) {
  return {
    type: 'MATCH_VIDEOS_ERROR',
    data,
  };
}

function fetchingMatchVideos() {
  return {
    type: 'MATCH_VIDEOS_FETCHING',
  };
}

function fetchMatchVideos(slug, updatedAt, forceRefresh) {
  return function (dispatch, state) {
    const {isFetchingVideos, videos} = state;
    if ((videos.length || isFetchingVideos) && !forceRefresh) {
      return;
    }
    dispatch(fetchingMatchVideos());
    return getMatchVideos(slug, updatedAt)
      .then(response => {
        dispatch(updateMatchVideos(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(errorMatchVideos(errorData));
      });
  };
}

// MATCH_LINEUP_PAGE
function fetchingLineup() {
  return {
    type: 'MATCH_LINEUP_FETCHING',
  };
}

function updateLineup(data) {
  return {
    type: 'MATCH_LINEUP_UPDATE',
    data,
  };
}

function errorLineup(data) {
  return {
    type: 'MATCH_LINEUP_ERROR',
    data,
  };
}

function updateSelectedTeam(data) {
  return {
    type: 'MATCH_LINEUP_SELECTED_TEAM_UPDATE',
    data,
  };
}

function fetchMatchLineup(slug) {
  return function (dispatch, state) {
    if (Object.keys(state.lineup).length) {
      return;
    }
    dispatch(fetchingLineup());
    return getLineup(slug)
      .then(response => {
        dispatch(updateLineup(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(errorLineup(errorData));
      });
  };
}

function fetchMatchLineupSSR(slug) {
  return function (dispatch) {
    dispatch(fetchingLineup());
    return getLineup(slug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'MatchLineUpPage',
            data: {lineup: response.data, isFetching: false, error: null, selectedTeam: 'home'},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(
          updateHistory('undefined', {
            store: 'MatchLineUpPage',
            data: {lineup: {}, error: errorData, isFetching: false, selectedTeam: 'home'},
          })
        );
      });
  };
}

// MATCH_STANDING_PAGE
function fetchingStanding() {
  return {
    type: 'MATCH_STANDING_FETCHING',
  };
}

function updateStanding(data) {
  return {
    type: 'MATCH_STANDING_UPDATE',
    data,
  };
}

function errorStanding(data) {
  return {
    type: 'MATCH_STANDING_ERROR',
    data,
  };
}

function fetchStandingByMatchSSR(matchSlug) {
  return function (dispatch) {
    return getStandingByMatch(matchSlug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'MatchStandingPage',
            data: {content: response.data, isFetching: false, error: null},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(
          updateHistory('undefined', {
            store: 'MatchStandingPage',
            data: {content: {}, isFetching: false, error: errorData},
          })
        );
      });
  };
}

function fetchStandingByMatch(matchSlug) {
  return function (dispatch, state) {
    if (Object.keys(state.content).length) {
      return;
    }
    dispatch(fetchingStanding());
    return getStandingByMatch(matchSlug)
      .then(response => {
        dispatch(updateStanding(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(errorStanding(errorData));
      });
  };
}

// MATCH_INFO_PAGE
function fetchingInfo() {
  return {
    type: 'MATCH_INFO_FETCHING',
  };
}

function updateInfo(data) {
  return {
    type: 'MATCH_INFO_UPDATE',
    data,
  };
}

function errorInfo(data) {
  return {
    type: 'MATCH_INFO_ERROR',
    data,
  };
}

function fetchMatchInfoSSR(matchSlug) {
  return function (dispatch) {
    return getMatchInfo(matchSlug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'MatchInfoPage',
            data: {data: response.data, isFetching: false, error: null},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(
          updateHistory('undefined', {
            store: 'MatchInfoPage',
            data: {data: {}, isFetching: false, error: errorData},
          })
        );
      });
  };
}

function fetchMatchInfo(matchSlug) {
  return function (dispatch, state) {
    if (Object.keys(state.data).length) {
      return;
    }
    dispatch(fetchingInfo());
    return getMatchInfo(matchSlug)
      .then(response => {
        dispatch(updateInfo(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(errorInfo(errorData));
      });
  };
}

export {
  fetchMatchMetaDataSSR,
  fetchMatchMetaData,
  fetchMatchTicker,
  fetchMatchVideos,
  fetchMatchLineupSSR,
  fetchMatchLineup,
  updateSelectedTeam,
  fetchStandingByMatchSSR,
  fetchStandingByMatch,
  fetchMatchInfoSSR,
  fetchMatchInfo,
  overwriteTicker,
  postTickerLike,
  deleteTickerLike,
  websocketUpdateMatchVideos,
  websocketAddMatchVideos,
  wsUpdateTicker,
  wsDeleteTicker,
  wsUpdateHighlights,
  wsDeleteHighlights,
  wsUpdateMatch,
};
